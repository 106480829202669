import ApiService from '@/services/core/api.service'

const BudgetActualService = {
  async get (query) {
    return ApiService.get(`disbursement/budget-actuals?${query}`)
  },

  async post (payload) {
    return ApiService.post('disbursement/budget-actuals', payload)
  },

  async put (payload) {
    return ApiService.put(`disbursement/budget-actuals/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`disbursement/budget-actuals/${payload.id}`)
  }
}

export default BudgetActualService
