import ApiService from '@/services/core/api.service'

const PendingApprovalService = {
  async get (query) {
    return ApiService.get(`tax-accountant/pending-approvals?${query}`)
  },

  async post (payload) {
    return ApiService.post('tax-accountant/pending-approvals', payload)
  },

  async put (payload) {
    return ApiService.put(`tax-accountant/pending-approvals/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`tax-accountant/pending-approvals/${payload.id}`)
  }
}

export default PendingApprovalService
