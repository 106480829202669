import ApiService from '@/services/core/api.service'

const PendingTransactionService = {
  async get (query) {
    return ApiService.get(`disbursement/pending-transactions?${query}`)
  },

  async post (payload) {
    return ApiService.post('disbursement/pending-transactions', payload)
  },

  async put (payload) {
    return ApiService.put(`disbursement/pending-transactions/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`disbursement/pending-transactions/${payload.id}`)
  }
}

export default PendingTransactionService
