import ApiService from '@/services/core/api.service'

const ReportService = {
  async getRequestHistories (query) {
    return ApiService.get(`user/reports/request-histories?${query}`)
  },

  async getApprovalHistories (query) {
    return ApiService.get(`user/reports/approval-histories?${query}`)
  }
}

export default ReportService
