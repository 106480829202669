import ApiService from '@/services/core/api.service'

const AttachmentService = {
  async upload (payload) {
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return ApiService.post('user/attachments/upload', payload, header)
  },

  async preview (filePath, fileName) {
    return ApiService.get(`user/attachments/preview?file_path=${filePath}&file_name=${fileName}`)
  },

  async retrieve (requestId, fileName) {
    return ApiService.get(`user/attachments/retrieve?request_id=${requestId}&file_name=${fileName}`)
  },

  async removeAllFile (filePath) {
    return ApiService.delete(`user/attachments/remove-all-file?file_path=${filePath}`)
  },

  async removeFile (filePath, fileName) {
    return ApiService.delete(`user/attachments/remove-file?file_path=${filePath}&file_name=${fileName}`)
  }
}

export default AttachmentService
