
import Vue from 'vue'
import * as rules from 'vee-validate/dist/rules'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'

localize({
  en: {
    messages: {
      required: field => {
        return `The ${field} field is required`
      },

      email: field => {
        return `The ${field} must be a valid email address`
      }
    }
  }
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
