import ApiService from '@/services/core/api.service'

const EmployeeService = {
  async get (query) {
    return ApiService.get(`admin/employees?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/employees', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/employees/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`admin/employees/${payload.id}`)
  }
}

export default EmployeeService
