import ApiService from '@/services/core/api.service'

const AttachmentService = {
  async retrieve (requestId, fileName) {
    return ApiService.get(`auditor/attachments/retrieve?request_id=${requestId}&file_name=${fileName}`)
  },

  async retrieveForm (fileName) {
    return ApiService.get(`auditor/forms/retrieve?file_name=${fileName}`)
  }
}

export default AttachmentService
