function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/disbursement/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const disbursementRoutes = [
  {
    path: '/disbursement/dashboard',
    component: view('Dashboard'),
    name: 'disbursement.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/disbursement/pending-transactions',
    component: view('PendingTransactions'),
    name: 'disbursement.pending-transactions',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Pending Transactions',
          active: true
        }
      ]
    }
  },
  {
    path: '/disbursement/maintenance/budgets',
    component: view('Budgets'),
    name: 'disbursement.budgets',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Budgets',
          active: true
        }
      ]
    }
  },
  {
    path: '/disbursement/maintenance/budget-actuals',
    component: view('BudgetActuals'),
    name: 'disbursement.budget-actuals',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Budget Actuals',
          active: true
        }
      ]
    }
  },
  {
    path: '/disbursement/maintenance/item-descriptions',
    component: view('ItemDescriptions'),
    name: 'disbursement.item-descriptions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Item Descriptions',
          active: true
        }
      ]
    }
  },
  {
    path: '/disbursement/reports/transaction-histories',
    component: view('TransactionHistories'),
    name: 'disbursement.transaction-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Transaction Histories',
          active: true
        }
      ]
    }
  }
]

export default disbursementRoutes
