import sharedRoutes from './shared-routes'
import adminRoutes from './admin-routes'
import auditorRoutes from './auditor-routes'
import taxAccountantRoutes from './taxaccountant-routes'
import disbursementRoutes from './disbursement-routes'
import userRoutes from './user-routes'

const allRoutes = []

export default allRoutes.concat(
  sharedRoutes,
  adminRoutes,
  auditorRoutes,
  taxAccountantRoutes,
  disbursementRoutes,
  userRoutes
)
