function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const adminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/user-management/user-accounts',
    component: view('UserAccounts'),
    name: 'admin.user-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'User Management'
        },
        {
          text: 'User Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/user-management/employee-accounts',
    component: view('EmployeeAccounts'),
    name: 'admin.employee-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'User Management'
        },
        {
          text: 'Employee Accounts',
          active: true
        }
      ]
    }
  }
]

export default adminRoutes
