import ApiService from '@/services/core/api.service'

const MyRequestService = {
  async get (query) {
    return ApiService.get(`user/my-requests?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/my-requests', payload)
  },

  async put (payload) {
    return ApiService.put(`user/my-requests/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`user/my-requests/${payload.id}`)
  }
}

export default MyRequestService
