function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/tax-accountant/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const taxAccountantRoutes = [
  {
    path: '/tax-accountant/dashboard',
    component: view('Dashboard'),
    name: 'tax-accountant.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/tax-accountant/pending-approvals',
    component: view('PendingApprovals'),
    name: 'tax-accountant.pending-approvals',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Pending Approvals',
          active: true
        }
      ]
    }
  },
  {
    path: '/tax-accountant/maintenance/suppliers',
    component: view('Suppliers'),
    name: 'tax-accountant.suppliers',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Suppliers',
          active: true
        }
      ]
    }
  },
  {
    path: '/tax-accountant/maintenance/tax-codes',
    component: view('TaxCodes'),
    name: 'tax-accountant.tax-codes',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Tax Codes',
          active: true
        }
      ]
    }
  },
  {
    path: '/tax-accountant/reports/approval-histories',
    component: view('ApprovalHistories'),
    name: 'tax-accountant.approval-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Approval Histories',
          active: true
        }
      ]
    }
  }
]

export default taxAccountantRoutes
