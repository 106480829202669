import store from '@/store'

export default async (to, from, next) => {
  const user = store.getters['auth/user']

  if (user.role !== 'auditor') {
    const routeName = `${user.role}.dashboard`
    next({ name: routeName })
  } else {
    next()
  }
}
