import ApiService from '@/services/core/api.service'

const ActionService = {
  async postRequestCancellation (payload) {
    return ApiService.post(`user/actions/request-cancellation/${payload.id}`, payload)
  },

  async postRequestApproval (payload) {
    return ApiService.post(`user/actions/request-approval/${payload.id}`, payload)
  }
}

export default ActionService
