import ApiService from '@/services/core/api.service'

const ActionService = {
  async posTagSingleAsCompleted (payload) {
    return ApiService.post('disbursement/actions/tag-single-as-completed', payload)
  },

  async postTagMultipleAsCompleted (payload) {
    return ApiService.post('disbursement/actions/tag-multiple-as-completed', payload)
  }
}

export default ActionService
