import ApiService from '@/services/core/api.service'

const SearchService = {
  async getSupplier (query) {
    return ApiService.get(`shared/search/suppliers?${query}`)
  },

  async getItemDescription (query) {
    return ApiService.get(`shared/search/item-descriptions?${query}`)
  },

  async getTaxCode (query) {
    return ApiService.get(`shared/search/tax-codes?${query}`)
  },

  async getEmployee (query) {
    return ApiService.get(`shared/search/employees?${query}`)
  }
}

export default SearchService
