import ApiService from '@/services/core/api.service'

const SupplierService = {
  async get (query) {
    return ApiService.get(`tax-accountant/suppliers?${query}`)
  },

  async post (payload) {
    return ApiService.post('tax-accountant/suppliers', payload)
  },

  async put (payload) {
    return ApiService.put(`tax-accountant/suppliers/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`tax-accountant/suppliers/${payload.id}`)
  }
}

export default SupplierService
