import ApiService from '@/services/core/api.service'

const ItemDescriptionService = {
  async get (query) {
    return ApiService.get(`disbursement/item-descriptions?${query}`)
  },

  async post (payload) {
    return ApiService.post('disbursement/item-descriptions', payload)
  },

  async put (payload) {
    return ApiService.put(`disbursement/item-descriptions/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`disbursement/item-descriptions/${payload.id}`)
  }
}

export default ItemDescriptionService
