function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/auditor/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const auditorRoutes = [
  {
    path: '/auditor/dashboard',
    component: view('Dashboard'),
    name: 'auditor.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/auditor/payment-requests',
    component: view('PaymentRequests'),
    name: 'auditor.payment-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/auditor/report/payment-requests',
    component: view('ReportPaymentRequest'),
    name: 'auditor.report.report-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Report'
        },
        {
          text: 'Report Requests',
          active: true
        }
      ]
    }
  }
]

export default auditorRoutes
