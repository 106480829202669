import ApiService from '@/services/core/api.service'

const ListService = {
  async getEmployeeList (includeInactive = 'no') {
    return ApiService.get(`shared/list/employees?include_inactive=${includeInactive}`)
  },

  async getBudgetList (includeInactive = 'no') {
    return ApiService.get(`shared/list/budgets?include_inactive=${includeInactive}`)
  },

  async getBudgetActualList (includeInactive = 'no') {
    return ApiService.get(`shared/list/budget-actuals?include_inactive=${includeInactive}`)
  },

  async getItemDescriptionList (budgetId = 0, includeInactive = 'no') {
    return ApiService.get(`shared/list/item-descriptions?budget_id=${budgetId}&include_inactive=${includeInactive}`)
  },

  async getTaxCodeList (includeInactive = 'no') {
    return ApiService.get(`shared/list/tax-codes?include_inactive=${includeInactive}`)
  },

  async getTaxAccountantList (includeInactive = 'no') {
    return ApiService.get(`shared/list/tax-accountants?include_inactive=${includeInactive}`)
  }
}

export default ListService
